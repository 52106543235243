import {useUpdatingText} from "./updatingTextService";
import {Position} from "./utils";

type UpdatingTextLineProps = {
    cursor?: Position;
    text: string;
}

const UpdatingTextLine = ({cursor, text}: UpdatingTextLineProps) => {
    if (!cursor) {
        return <div className={"line"}>{text}</div>;
    } else {
        return <div className={"line"}>
            {text.substr(0, cursor.x)}
            <span className={"cursor"}>{text.substr(cursor.x, 1)}</span>
            {cursor.x < text.length - 1 && text.substr(cursor.x + 1, text.length - cursor.x - 1)}
        </div>
    }
}

type UpdatingTextProps = {
    text: string;
    replacements: {[search in string]: string[]};
    lineLength: number;
}

export const UpdatingText = ({text, replacements, lineLength}: UpdatingTextProps) => {
    const {grid, cursor} = useUpdatingText(text, replacements, lineLength);

    return (
        <div className="updating-text">
            {grid.map((line, y) => (
                <UpdatingTextLine key={y} text={line} cursor={!!cursor && cursor.y === y ? cursor : undefined}/>
            ))}
        </div>
    )
}
