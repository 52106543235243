export type Position = {
    x: number;
    y: number;
}

const WHITESPACE = "\u00A0";

export function convertTextToGrid(text, lineLength): string[] {
    const words = text.split(" ");
    let lines: string[][] = [[]];
    let currentLineLength = 0;
    words.forEach((word) => {
        if (currentLineLength + word.length + 1 < lineLength) {
            lines[lines.length - 1].push(word);
            currentLineLength += word.length + 1;
        } else {
            lines.push([word]);
            currentLineLength = word.length;
        }
    });
    return lines.map((line) => line.join(WHITESPACE).padEnd(lineLength, WHITESPACE));
}

export function updateWithInsert(at: Position, character: string, currentText: string, currentGrid: string[], lineLength: number
): { newText: string, newGrid: string[], newCursor: Position } {
    let characterPosition = findCharacterPosition(at, currentGrid) + 1;

    const newText = currentText.substr(0, characterPosition) + character + currentText.substr(characterPosition);
    const newGrid = convertTextToGrid(newText, lineLength);
    characterPosition += 1
    const newCursor = findCursorPosition(characterPosition, newGrid);

    return {
        newText, newCursor, newGrid
    };
}

export function updateWithDelete(at: Position, currentText: string, currentGrid: string[], lineLength: number) {
    if (at.x === 0 && at.y === 0) {
        return;
    }
    let characterPosition = findCharacterPosition(at, currentGrid);
    const newText = currentText.substr(0, characterPosition) + currentText.substr(characterPosition + 1);
    const newGrid = convertTextToGrid(newText, lineLength);
    const newCursor = findCursorPosition(characterPosition, newGrid);

    return {
        newText, newCursor, newGrid
    };
}

function findCharacterPosition(at: Position, grid: string[]): number {
    let characterPosition = at.x - 1;
    let y = at.y;
    while (y > 0) {
        const extraOffset = grid[y - 1].endsWith(WHITESPACE) ? 1 : 0;
        characterPosition += grid[y - 1].trim().length + extraOffset;
        y -= 1;
    }
    return characterPosition
}

export function findCursorPosition(characterPosition: number, grid: string[]): Position {
    const newCursor = {x: 0, y: 0};
    while (characterPosition > 0) {
        const extraOffset = grid[newCursor.y].endsWith(WHITESPACE) ? 1 : 0;
        const lineLength = grid[newCursor.y].trim().length + extraOffset;
        if (lineLength + 1 <= characterPosition) {
            characterPosition -= lineLength;
            newCursor.y = newCursor.y + 1;
        } else {
            newCursor.x = characterPosition;
            characterPosition -= characterPosition;
        }
    }
    return newCursor
}

function moveCursorRight(cursor: Position): Position {
    return {
        x: cursor.x + 1,
        y: cursor.y,
    }
}

function moveCursorLeft(cursor: Position): Position {
    return {
        x: cursor.x - 1,
        y: cursor.y,
    }
}

function moveCursorUp(cursor: Position): Position {
    return {
        x: cursor.x,
        y: cursor.y - 1,
    }
}

function moveCursorDown(cursor: Position): Position {
    return {
        x: cursor.x,
        y: cursor.y + 1,
    }
}

export function moveCursorTowardsTarget(cursor: Position, target: Position): Position | undefined {
    if (!!cursor) {
        const availablePositions: Position[] = [];
        if (cursor.x < target.x) {
            availablePositions.push(moveCursorRight(cursor));
        }
        if (cursor.x > target.x) {
            availablePositions.push(moveCursorLeft(cursor));
        }
        if (cursor.y < target.y) {
            availablePositions.push(moveCursorDown(cursor));
        }
        if (cursor.y > target.y) {
            availablePositions.push(moveCursorUp(cursor));
        }

        if (availablePositions.length === 0) {
            return;
        }
        return availablePositions[Math.floor(Math.random() * availablePositions.length) % availablePositions.length]
    }
}