import React from "react";

let NUM_NODES = 10;

const BACKGROUND_COLOUR = "#151515";
const HIGHLIGHT_COLOR = "#6F1A07";
const BORDER_SIZE = 3;

class CanvasDemo extends React.Component {

    constructor(props) {
        super(props);

        this.canvasRef = React.createRef();
        this.state = {};
    }

    getNodes(WIDTH, HEIGHT, forceRegen) {
        let nodes = this.state.nodes;

        if (! nodes || forceRegen) {
            nodes = [];
            for (let i = 0; i < NUM_NODES; i++) {
                nodes.push({
                    x: Math.floor(Math.random() * WIDTH),
                    y: Math.floor(Math.random() * HEIGHT),
                    velocity: 1 + Math.random(),
                    weight: Math.random() * 10,
                    alpha: 1,
                    direction: 2 * Math.PI * Math.random()
                });
            }
        }
        return nodes;
    }

    runAnimation() {
        let canvas = this.canvasRef.current;
        if (!canvas) {
            return;
        }
        let WIDTH = canvas.width;
        let HEIGHT = canvas.height;
        let LONGEST_HYPOTENUSE = Math.hypot(WIDTH/2, HEIGHT/2);
        let ctx = canvas.getContext('2d');

        ctx.fillStyle = BACKGROUND_COLOUR;
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        let newNodes = [];
        let nodes = this.getNodes(WIDTH, HEIGHT, this.WIDTH !== WIDTH);
        this.WIDTH = WIDTH;

        nodes.forEach(node => {
           this.drawEdge(ctx, node.x, node.y);
        });

        ctx.fillStyle = BACKGROUND_COLOUR;
        ctx.fillRect(BORDER_SIZE, BORDER_SIZE, canvas.width - (BORDER_SIZE * 2), canvas.height - (BORDER_SIZE * 2));

        nodes.forEach(node => {
            this.drawNode(ctx, node.x, node.y, node.weight);

            let x = (Math.cos(node.direction) * node.velocity) + node.x;
            let y = (Math.sin(node.direction) * node.velocity) + node.y;
            let direction = node.direction;

            //
            if (x - node.weight - 1 < 0) {
                x = node.weight;
                direction = direction > Math.PI ? (3 * Math.PI) - direction : Math.PI - direction;
            }
            if (x + node.weight + 1 > WIDTH) {
                x = WIDTH - node.weight;
                direction = direction > Math.PI ? (3 * Math.PI) - direction : Math.PI - direction;
            }
            if (y - node.weight - 1 < 0) {
                y = node.weight;
                direction = (2 * Math.PI) - direction;
            }
            if (y + node.weight + 1 > HEIGHT) {
                y = HEIGHT - node.weight;
                direction = (2 * Math.PI) - direction;
            }

            if (direction !== node.direction) {
                direction += (Math.PI / 16) - (Math.PI / 8) * Math.random();
                direction = direction < 0
                    ? (2*Math.PI) + direction
                    : direction > (2*Math.PI) ? (2*Math.PI) - direction : direction;
            }

            let x1 = (WIDTH / 2) - Math.abs((WIDTH / 2) - x);
            let y1 = (HEIGHT / 2) - Math.abs((HEIGHT / 2) - y);
            let centreDist = Math.hypot(x1, y1);
            // At the furthest point the size should be 2, at the closest point
            // it should be 6.
            let weight = 4 * (centreDist / LONGEST_HYPOTENUSE) + 2

            newNodes.push({
                ...node, x, y, direction, weight
            })
        });

        if (this.playing) {
            setTimeout(this.runAnimation.bind(this), 25);
        }

        this.setState({...this.state, nodes: newNodes});
    }

    drawNode(ctx, x, y, r) {
        ctx.beginPath();
        ctx.fillStyle = HIGHLIGHT_COLOR;


        ctx.arc(x, y, r, 0, 2 * Math.PI);
        ctx.fill();
    }

    drawEdge(ctx, x, y) {
        ctx.beginPath();
        let grd = ctx.createRadialGradient(x, y, 3, x, y, 30);
        grd.addColorStop(0, HIGHLIGHT_COLOR);
        grd.addColorStop(0.5, BACKGROUND_COLOUR);
        ctx.fillStyle = grd;

        ctx.arc(x, y, 30, 0, 2 * Math.PI);
        ctx.fill();
    }

    componentDidMount() {
        this.playing = true;
        setTimeout(this.runAnimation.bind(this), 50);
        window.addEventListener("resize", () => {
            if (!!this.canvasRef && !!this.canvasRef.current) {
                this.canvasRef.current.height = window.innerHeight;
            }
        });
        if (!!this.canvasRef) {
            this.canvasRef.current.height = window.innerHeight;
        }
    }

    componentWillUnmount() {
        this.playing = false
    }

    render() {
        return (
            <div className={"canvas-container"}>
                <canvas
                    className="demo-canvas"
                    width={(window.innerWidth - 920) / 2}
                    height={"100px"}
                    ref={this.canvasRef}
                />
            </div>
        )
    }
}

export default CanvasDemo;