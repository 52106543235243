import {UpdatingText} from "./updatingText/UpdatingText";
import {NetcraftLogo} from "./logos/netcraft";
import {PalantirLogo} from "./logos/palantir";
import {OptimalLogo} from "./logos/optimal";
import {ProjectsLogo} from "./logos/projects";
import {useEffect, useState} from "react";

const experience = 'Hi, I\'m Ben. I\'m a Software Engineer with several years\' experience in the computer software industry.';
const experience2 = 'I\'m not looking for work right now. Get in touch if you want to talk about anything else!';
const projects1 = 'Currently I spend a lot of time working on a few different projects, some tech focussed, others less so. This year my goals focus on improving my cycling endurance, increasing the garden yields and occasionally doing some actual work.';
const projects2 = 'In 2021 I played around with (Hashicorp) Nomad and Consul, as well as a couple of small projects with Kotlin. I also spent some time contracting with a logistics company and helped a researcher with some geospatial analysis. Mainly though a lot of time was occupied in the garden: trying to grow a selection of vegetables.'
const projects3 = 'In 2020 I built an artifact hosting service from scratch - for hosting docker images, npm packages, maven artifacts and python packages. I also created a GIS tool for analysing the UK housing market, as well as a supermarket price comparison/history site.'
const optimal1 = 'I was a Senior Software Engineer at Optimal Labs in London. Their mission is to grow safer, healthier food by deploying fully autonomous greenhouses outside every city on earth.';
const optimal2 = 'I worked on the core platform used by Optimal\'s customers and the Advanced Control Team. The technologies I worked with the most were GCP, Terraform, Python and Typescript.';
const palantir1 = 'My role within business development primarily included software development, data integration, analysis and system management to enable organisations to deliver outcomes against their most important mission objectives. I occasionally worked on pitches, architecture design, subject matter expert interviews and trainings.';
const palantir2 = 'At Palantir I worked with a broad range of technologies and programming languages. I gained experience managing the full technology stack for enterprise deployments, from installing hardware in the datacenter to building bespoke software solutions for clients.';
const palantir3 = 'Whilst at Palantir I regularly took on the Technical Lead role, and took the opportunity where I could to develop, mentor and lead other engineers.';
const netcraft1 = 'At Netcraft I worked on developing novel approaches to increasing automation when classifying websites, increasing the accuracy of the false positive mechanism and developing new products to analyse evolving attacks.';
const netcraft2 = 'I also worked on a number of their other services; from conducting takedowns on websites phishing against Netcraft’s clients, to adding tests for the latest security issues in their automated security scanning. Whilst I was there I embraced the opportunity to train and manage new members of staff.';

const experienceReplacments = {
    "rs'": ["rs of", "rs", "r's", "... I'm a coder, not a grammaticist! "],
    "Hi": ["Greetings", "Salutations", "Hey there"],
    "software": ["tehcno", "technology"],
    "Ben": ["Benjamin"],
};

const experience2Replacements = {
    "right now": ["at the moment"],
    " want": ["'d like"],
    "!": [" - keen to know more about home made sourdough!"]
};

const project1Replacements = {
    "doing some actual work": ["obtaining capital"],
    "yields": ["deliciousness"]
};

const project2Replacements = {
    "tlin.": ["tlin - which I only wound up using in earnest for Advent of Code!"],
    "trying": ["trying, and failing,"],
    "bles": ["bles and bend nature to my will.."]
};

const project3Replacements = {
    " from scratch": [", ImageCart"],
    "analysing": ["triaging"]
};

const optimal1Replacements = {
    "London": ["Hammersmith"],
    "mission": ["goal", "quest", "objective"],
};

const optimal2Replacements = {
    "core platform": ["central infrastructure"],
    "Typescript": ["React", "Node"],
    "GCP": ["Google Clou"]
};

const palantir1Replacements = {
    "role": ["job", "position"],
    "I occasionally": ["At times I"],
};

const palantir2Replacements = {
    "technologies": ["software"],
    "in the datacenter": ["onsite"],
};

const palantir3Replacements = {
    "took on": ["held"],
    "engineers": ["software engineers"],
};

const netcraft1Replacements = {
    "products": ["solutions", "hacks", "tools", "scripts"],
    "novel": ["bespoke", "original", "cutting edge"],
};

const netcraft2Replacements = {
    "services": ["offerings"],
    "clients": ["customers"],
};

const MAX_LINE_LENGTH = 90;
const MAX_JOB_LENGTH = 82;

export const Experience = () => {
    const [vw, setVw] = useState(Math.max(document.documentElement.clientWidth, window.innerWidth || 0))

    useEffect(() => {
        const listener = () => setVw(Math.max(document.documentElement.clientWidth, window.innerWidth || 0))
        window.addEventListener("resize", listener);
        return () => window.removeEventListener("resize", listener)
    }, [setVw]);

    const experienceLineLength = (vw < 10 * MAX_LINE_LENGTH) ?  Math.floor(vw / 10) : MAX_LINE_LENGTH;
    const jobLineLength = (vw < 10 * MAX_LINE_LENGTH) ?  Math.floor(vw / 10) - 12 : MAX_JOB_LENGTH;

    return (
        <div className="section experience">
            <UpdatingText text={experience} replacements={experienceReplacments} lineLength={experienceLineLength}/>
            <UpdatingText text={experience2} replacements={experience2Replacements} lineLength={experienceLineLength}/>
            <div className={"job"}>
                <div><ProjectsLogo /></div>
                <div>
                    <div className={"title"}>Projects</div>
                    <div><i>2020 - onwards</i></div>
                    <UpdatingText text={projects1} replacements={project1Replacements} lineLength={jobLineLength}/>
                    <UpdatingText text={projects2} replacements={project2Replacements} lineLength={jobLineLength}/>
                    <UpdatingText text={projects3} replacements={project3Replacements} lineLength={jobLineLength}/>
                </div>
            </div>
            <div className={"job"}>
                <div><OptimalLogo /></div>
                <div>
                    <div className={"title"}><a href={"https://optimal.ag"}>Optimal</a></div>
                    <div><i>2019 - 2021</i></div>
                    <UpdatingText text={optimal1} replacements={optimal1Replacements} lineLength={jobLineLength}/>
                    <UpdatingText text={optimal2} replacements={optimal2Replacements} lineLength={jobLineLength}/>
                </div>
            </div>
            <div className={"job"}>
                <div><PalantirLogo /></div>
                <div>
                    <div className={"title"}><a href={"https://palantir.com"}>Palantir Technologies</a></div>
                    <div><i>2013 - 2019</i></div>
                    <UpdatingText text={palantir1} replacements={palantir1Replacements} lineLength={jobLineLength}/>
                    <UpdatingText text={palantir2} replacements={palantir2Replacements} lineLength={jobLineLength}/>
                    <UpdatingText text={palantir3} replacements={palantir3Replacements} lineLength={jobLineLength}/>
                </div>
            </div>
            <div className={"job"}>
                <div><NetcraftLogo /></div>
                <div>
                    <div className={"title"}><a href={"https://netcraft.com"}>Netcraft</a></div>
                    <div><i>2010 - 2013</i></div>
                    <UpdatingText text={netcraft1} replacements={netcraft1Replacements} lineLength={jobLineLength}/>
                    <UpdatingText text={netcraft2} replacements={netcraft2Replacements} lineLength={jobLineLength}/>
                </div>
            </div>
        </div>
    );
}