const logo = `bbbbbbbbbbbbbbbbbbbbbbbb
bbbbbbbbbbbbbbbbbbbbbbbb
bbbbbbbbbbbbbbbbbbbbbbbb
bbbbbbbbbbwwwwbbbbbbbbbb
bbbbbbbbwwbbbbwwbbbbbbbb
bbbbbbbwwbbbbbbwwbbbbbbb
bbbbbbbwwbbbbbbwwbbbbbbb
bbbbbbbbwwbbbbwwbbbbbbbb
bbbbbbwwbbwwwwbbwwbbbbbb
bbbbbbbbwwbbbbwwbbbbbbbb
bbbbbbbbbbwwwwbbbbbbbbbb
bbbbbbbbbbbbbbbbbbbbbbbb
bbbbbbbbbbbbbbbbbbbbbbbb
bbbbbbbbbbbbbbbbbbbbbbbb`;

const blockChars = "&#8B@%$";

type PalantirLineProps = {
    line: string;
}

const PalantirLine= ({line}: PalantirLineProps) => {
    const chunks = [];
    line.split("").forEach((char) => {
        if (chunks.length === 0) {
            chunks.push([char]);
        } else if (chunks[chunks.length - 1][0] === char) {
            chunks[chunks.length - 1].push(char);
        } else {
            chunks.push([char]);
        }
    });
    const spans = chunks.map((chunk, i) =>
        (<span key={i} className={"color-"+chunk[0]}>
                {chunk.map(() => blockChars[(blockChars.length * Math.random()) & blockChars.length]).join("")}
            </span>)
    )

    return (
        <div className={"line"}>
            {spans}
        </div>
    );
}

export const PalantirLogo = () => {
    const lines = logo.split("\n");
    return (
        <div className={"logo palantir"}>
            <a href={"https://palantir.com"}>
                {lines.map((line, y) => <PalantirLine key={y} line={line} />)}
            </a>
        </div>
    );
}