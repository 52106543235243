import {useCallback, useEffect, useState} from "react";

const logo = `ggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggg
ggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggg
ggggggggggggggggggggggggggggggggggggggggggggkrpnrgggggggggggggggggggggggggggggggggggg
kkkkkkgggggggwwwwwwwwwwwwwwwwgggggggggggggggkrpnrppggggggggggbbbgggbbbgggggggggkkkkkk
pppppkgggggggwwwwwwwwwwwwwwwwggggggggggggggbbbbbbbbbbbgggggggbkbgggbkbgggggggggkppppp
pppppkgggggggwwsssssssssssswwgggggggggggggggbgggggggbgggggggggbgggggbggggggggggkppppp
pppppkgggggggwwsssssssssssswwgggggggggggggggggggggggggggggggggbgggggbggggggggggkppppp
kkkkkkgggggggwwsssssssssssswwggggggggggkkkggggkkggggggggggggggbgggggbggggggggggkkkkkk
kggggggggggggwwsshhhhhhhhsswwgggggggggggwgggggwgggggggggggggggbgggggbgggggggggggggggk
kkgggggggggggwwhhhhhhhhhhhhwwgggggggkkkgwwwwwwwwkkkgggggggggggwgggggwggggggggggggggkk
yyyyyyyyygggbbbbbbbbbbbbbbbbbbgggggkgggkwgggggwwgggkgggggggggwwwgggwwwggggggyyyyyyyyy
ggnnnnnnngggggggggggggggggggggggggkgggwgwgwwwgkgwgggkggggggggwwwgggwgwggggggnnnnnnngg
ggnnnnnnngggggggggggggggggggggggggkggwwwwwggggkggwggkgggggggggggggggggggggggnnnnnnngg`;

const LOGO_SPLITS = logo.split("\n")
const LOGO_LINE_LENGTH = LOGO_SPLITS[0].length
const blockChars = "&#8B@%$";

type ProjectsLineProps = {
    line: string;
    frame: number;
}

const ProjectsLine = ({line, frame}: ProjectsLineProps) => {
    const chunks = [];
    const chars = (
        line.slice(frame, Math.min(frame + 24, line.length))
        + line.slice(0, Math.max(frame + 24 - line.length, 0))
    )
    chars.split("").forEach((char) => {
        if (chunks.length === 0) {
            chunks.push([char]);
        } else if (chunks[chunks.length - 1][0] === char) {
            chunks[chunks.length - 1].push(char);
        } else {
            chunks.push([char]);
        }
    });

    return (
        <div className={"line"}>
            {chunks.map((chunk, i) => (
                <span key={i} className={`color-${chunk[0]}`}>
                    {chunk.map(() => blockChars[(blockChars.length * Math.random()) & blockChars.length]).join("")}
                </span>
            ))}
        </div>
    );
}

export const ProjectsLogo = () => {
    const [frame, setFrame] = useState(0);
    const [isMouseOver, setIsMouseOver] = useState(false);

    useEffect(() => {
        if (isMouseOver) {
            let timeout = null;
            const animateFrame = () => {
                timeout = setTimeout(() => {
                    setFrame(frame => (frame + 1) % LOGO_LINE_LENGTH);
                    animateFrame();
                }, 100)
            }
            animateFrame();
            return () => {
                clearTimeout(timeout)
            }
        }
    }, [isMouseOver])

    const handleMouseEnter = useCallback(() => setIsMouseOver(true), [setIsMouseOver]);
    const handleMouseLeave = useCallback(() => setIsMouseOver(false), [setIsMouseOver]);

    return (
        <div className={"logo projects"} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {LOGO_SPLITS.map((line, y) => <ProjectsLine key={y} line={line} frame={frame} />)}
        </div>
    )
}