const logo = `bbbbbbbbbbbbbbbbbbbbbbbb
bbbbbbbbbbbbbbbbbbbbbbbb
bbbbbbbbbbbbbbbbbbbbbbbb
bbbbbbwwwbwbbbbbbbbbbbbb
bbbbbwbbbwbbbbwwwbbbbbbb
bbbbwbbbbbwbbwbbbwwbbbbb
bwwwbbbbbbbwwbbbbbbwwwwb
bbbbbbbbbbbbbbbbbbbbbbbb
bbb+-+-+-+-||bb|+-+|bbbb
bbb|b|-+b|b||\\/|+-+|bbbb
bbb+-+bbb|b||bb||b|+--bb
bbbbbbbbbbbbbbbbbbbbbbbb
bbbbbbbbbbbbbbbbbbbbbbbb`;

const blockChars = "&#8B@%$";

type OptimalLineProps = {
    line: string;
}

const OptimalLine = ({line}: OptimalLineProps) => {
    const chunks = [];
    line.split("").forEach((char) => {
        if (chunks.length === 0) {
            chunks.push([char]);
        } else if (chunks[chunks.length - 1][0] === char) {
            chunks[chunks.length - 1].push(char);
        } else {
            chunks.push([char]);
        }
    });
    const spans = chunks.map((chunk, i) =>
        (<span key={i} className={"color-"+(chunk[0] === "b" ? "b": "w")}>
                {chunk.map(() => blockChars[(blockChars.length * Math.random()) & blockChars.length]).join("")}
            </span>)
    )

    return (
        <div className={"line"}>
            <a href={"https://optimal.ag"}>
                {spans}
            </a>
        </div>
    );
}

export const OptimalLogo = () => {
    const lines = logo.split("\n");
    return (
        <div className={"logo optimal"}>
            {lines.map((line, y) => <OptimalLine key={y} line={line} />)}
        </div>
    );
}
