import React from 'react';
import classNames from 'classnames';

const fillerCharacters = ":::::::::::::::&+x|#";
const invalidCharacters = "/><\\[]{}1234567890";
const rawAscii = `                                  BBBBBBBBBBBBBBBBB                                                                               
                                  B::::::::::::::::B                                                                              
                                  B::::::BBBBBB:::::B                                                                             
                                  BB:::::B     B:::::B                                                                            
                                    B::::B     B:::::B    eeeeeeeeeeee    nnnn  nnnnnnnn                                          
                                    B::::B     B:::::B  ee::::::::::::ee  n:::nn::::::::nn                                        
                                    B::::BBBBBB:::::B  e::::::eeeee:::::een::::::::::::::nn                                       
                                    B:::::::::::::BB  e::::::e     e:::::enn:::::::::::::::n                                      
                                    B::::BBBBBB:::::B e:::::::eeeee::::::e  n:::::nnnn:::::n                                      
                                    B::::B     B:::::Be:::::::::::::::::e   n::::n    n::::n                                      
                                    B::::B     B:::::Be::::::eeeeeeeeeee    n::::n    n::::n                                      
                                    B::::B     B:::::Be:::::::e             n::::n    n::::n                                      
                                  BB:::::BBBBBB::::::Be::::::::e            n::::n    n::::n                                      
                                  B:::::::::::::::::B  e::::::::eeeeeeee    n::::n    n::::n                              dddddddd
        GGGGGGGGGGGGG             B::::::::::::::::B    ee:::::::::::::e    n::::n    n::::n                              d::::::d
     GGG::::::::::::G             BBBBBBBBBBBBBBBBB       eeeeeeeeeeeeee    nnnnnn    nnnnnn                              d::::::d
   GG:::::::::::::::G                                                                                                     d::::::d
  G:::::GGGGGGGG::::G                                                                                                     d:::::d 
 G:::::G       GGGGGG  aaaaaaaaaaaaa   zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz  aaaaaaaaaaaaa  rrrrr   rrrrrrrrr       ddddddddd:::::d 
G:::::G                a::::::::::::a  z:::::::::::::::zz:::::::::::::::z  a::::::::::::a r::::rrr:::::::::r    dd::::::::::::::d 
G:::::G                aaaaaaaaa:::::a z::::::::::::::z z::::::::::::::z   aaaaaaaaa:::::ar:::::::::::::::::r  d::::::::::::::::d 
G:::::G    GGGGGGGGGG           a::::a zzzzzzzz::::::z  zzzzzzzz::::::z             a::::arr::::::rrrrr::::::rd:::::::ddddd:::::d 
G:::::G    G::::::::G    aaaaaaa:::::a       z::::::z         z::::::z       aaaaaaa:::::a r:::::r     r:::::rd::::::d    d:::::d 
G:::::G    GGGGG::::G  aa::::::::::::a      z::::::z         z::::::z      aa::::::::::::a r:::::r     rrrrrrrd:::::d     d:::::d 
G:::::G        G::::G a::::aaaa::::::a     z::::::z         z::::::z      a::::aaaa::::::a r:::::r            d:::::d     d:::::d 
 G:::::G       G::::Ga::::a    a:::::a    z::::::z         z::::::z      a::::a    a:::::a r:::::r            d:::::d     d:::::d 
  G:::::GGGGGGGG::::Ga::::a    a:::::a   z::::::zzzzzzzz  z::::::zzzzzzzza::::a    a:::::a r:::::r            d::::::ddddd::::::dd
   GG:::::::::::::::Ga:::::aaaa::::::a  z::::::::::::::z z::::::::::::::za:::::aaaa::::::a r:::::r             d:::::::::::::::::d
     GGG::::::GGG:::G a::::::::::aa:::az:::::::::::::::zz:::::::::::::::z a::::::::::aa:::ar:::::r              d:::::::::ddd::::d
        GGGGGG   GGGG  aaaaaaaaaa  aaaazzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz  aaaaaaaaaa  aaaarrrrrrr               ddddddddd   ddddd`;

class Header extends React.Component {

    lockBorderAnim = false;

    constructor(props) {
        super(props);
        this.state = {
            grid: rawAscii.split("\n").map((line) => line.split("")),
            gridStyle: rawAscii.split("\n").map((line) => line.split("").map((char) => {
                switch (char) {
                    case " ": return "blank";
                    case ":": return "filler";
                    default: return "border";
                }
            })),
            animationStyle: rawAscii.split("\n").map((line) => line.split("").map((char) => undefined)),
        }
    }

    componentDidMount() {
        this.setState((state) => ({
            ...state,
            grid: state.grid.map((row) => row.map((char) => char === ":"
                ? fillerCharacters[Math.floor(Math.random() * fillerCharacters.length)]
                : char))
        }));
        this.borderShimmer();
        this.effectLoop()
    }

    effectLoop () {
        if (!this.lockBorderAnim && Math.floor(Math.random() * 60) % 60 === 0) this.borderShimmer();
        this.tweakRandomCharacter();
        setTimeout(this.effectLoop.bind(this), 100 + Math.round(Math.random() * 500));
    }

    tweakRandomCharacter() {
        const {grid} = this.state;
        let x = 0;
        let y = 0;
        while (grid[y][x] === " ") {
            y = Math.floor(Math.random() * grid.length);
            x = Math.floor(Math.random() * grid[y].length)
        }
        const originalChar = grid[y][x];

        this.setState((state) => {
            const newGrid = [...state.grid];
            newGrid[y] = [...grid[y]];
            newGrid[y][x] = invalidCharacters[Math.floor(Math.random() * invalidCharacters.length)];
            return {...state, grid: newGrid};
        });
        setTimeout(() =>
            this.setState((state) => {
                const newGrid = [...state.grid];
                newGrid[y] = [...state.grid[y]];
                newGrid[y][x] = originalChar;
                return {...state, grid: newGrid};
            }), 500 + Math.round(Math.random() * 2000)
        )
    }

    borderShimmer(c = 0) {
        const {gridStyle} = this.state;
        this.lockBorderAnim = true;
        setTimeout(() =>{
            const m = -1.8;
            this.setState((state) => {
                const newAnimationStyle = state.animationStyle.map(row => row.map(style => style));
                for (let x = 0; x < newAnimationStyle[0].length; x++) {
                    let yUpper = Math.floor(m * x + c);
                    let y2 = Math.floor(m * x + c - 10);
                    let y3 = Math.floor(m * x + c - 15);
                    let yLower = Math.floor(m * x + c - 30);
                    for (let y = 0; y <= yUpper && y < newAnimationStyle.length; y += 1) {
                        if (y >= y2 && gridStyle[y][x] === "border") {
                            newAnimationStyle[y][x] = "highlight";
                        } else if (y >= y3 && gridStyle[y][x] === "border") {
                            newAnimationStyle[y][x] = "highlight2";
                        } else if (y >= yLower && gridStyle[y][x] === "border") {
                            newAnimationStyle[y][x] = "highlight3";
                        } else if (gridStyle[y][x] === "border") {
                            newAnimationStyle[y][x] = "reset";
                        }
                    }
                }
                return {...state, animationStyle: newAnimationStyle};
            });


            if (c - 30 < gridStyle.length - (m * gridStyle[0].length)) {
                this.borderShimmer(c + 5);
            } else {
                setTimeout(() => this.lockBorderAnim = false, 5000);
            }
        }, 25);
    }

    render() {
        const {grid, gridStyle, animationStyle} = this.state;
        const vw = Math.max(window.outerWidth || 0);
        const style = {};
        if (vw < 900) {
            style['fontSize'] = `${10 * vw / 900}px`;
        } else {
            style['fontSize'] = `10px`;
        }
        return (
            <div style={style}>
            <div className="header">
                {grid.map((row, y) => {
                    const keyLookup = {};
                    return (
                        <div key={y} className={"row"}>
                            {row
                                .reduce((prev, char, x) => {
                                    const style = gridStyle[y][x];
                                    const animStyle = animationStyle[y][x];
                                    if (prev.length === 0) {
                                        prev.push([char, style, animStyle]);
                                        return prev;
                                    }
                                    const [prevChar, prevStyle, prevAnimStyle] = prev[prev.length - 1];
                                    if (prevChar[0] === char && prevStyle === style && prevAnimStyle === animStyle) {
                                        prev[prev.length - 1][0] = char + prevChar;
                                    } else {
                                        prev.push([char, style, animStyle]);
                                    }
                                    return prev;
                                }, [])
                                .map(([char, style, animStyle]) =>
                                    this.renderChunk(char, keyLookup, style, animStyle)
                            )}
                        </div>
                    )
                })}
            </div>
            </div>
        )
    }

    renderChunk(asciiInput, keyLookup, style, animationStyle) {
        if (!keyLookup.hasOwnProperty(asciiInput)) {
            keyLookup[asciiInput] = 0;
        }
        keyLookup[asciiInput] += 1;
        const classes = [
            "block", style, invalidCharacters.indexOf(asciiInput) > -1 ? "error" : "normal", animationStyle
        ];
        const char = asciiInput[0] === " "
            ? asciiInput.replace(/ /g, '\u00A0')
            : asciiInput;
        return (
            <span key={`${asciiInput}-${keyLookup[asciiInput]}`} className={classNames(classes)}>{char}</span>
        );
    }
}

export default Header;