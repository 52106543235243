const logo = `pppprrrrooooyyyyggggaaaa
pppprrrrooooyyyyggggaaaa
pppprrrrooooyyyyggggaaaa
pppprrbbbbbbbbbbbbggaaaa
pppprrbbbbbbbbbbbbggaaaa
pppprrbbbbooyybbbbggaaaa
pppprrbbbbooyybbbbggaaaa
pppprrbbbbooyybbbbggaaaa
pppprrbbbbooyybbbbggaaaa
pppprrbbbbooyybbbbggaaaa
pppprrrrooooyyyyggggaaaa
pppprrrrooooyyyyggggaaaa
pppprrrrooooyyyyggggaaaa`;

const blockChars = "&#8B@%$";

type NetcraftLineProps = {
    line: string
}

const NetcraftLine = ({line}: NetcraftLineProps) => {
    const chunks = [];
    line.split("").forEach((char) => {
        if (chunks.length === 0) {
            chunks.push([char]);
        } else if (chunks[chunks.length - 1][0] === char) {
            chunks[chunks.length - 1].push(char);
        } else {
            chunks.push([char]);
        }
    });
    const spans = chunks.map((chunk, i) =>
        (<span key={i} className={"color-"+chunk[0]}>
                {chunk.map(() => blockChars[(blockChars.length * Math.random()) & blockChars.length]).join("")}
            </span>)
    )

    return (
        <div className={"line"}>
            <a href={"https://netcraft.com"}>
                {spans}
            </a>
        </div>
    );
}

export const NetcraftLogo = () => {
    const lines = logo.split("\n");
    return (
        <div className={"logo netcraft"}>
            {lines.map((line, y) => <NetcraftLine key={y} line={line} />)}
        </div>
    );
}
