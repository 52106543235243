let updatingTextLockedBy = undefined;
const updatingTextRegistry = {};

export function triggerUpdaterFromRegistry(): string {
    console.log("triggered")
    const allUpdatingTextIds = Object.keys(updatingTextRegistry);
    if (allUpdatingTextIds.length === 0) {
        console.error("no updaters were registered")
        return;
    }
    const nextUpdatingTextId = allUpdatingTextIds[Math.floor(allUpdatingTextIds.length * Math.random()) % allUpdatingTextIds.length];
    updatingTextLockedBy = nextUpdatingTextId;
    updatingTextRegistry[nextUpdatingTextId]();
    return nextUpdatingTextId;
}

export function registerControlLoopCallback(id: string, controlLoop: () => void) {
    console.log(`Registered ${id}`)
    updatingTextRegistry[id] = controlLoop;
}

export function removeControlLoopCallback(id: string) {
    delete updatingTextRegistry[id];
}

export function getRegistryLock(id: string): boolean {
    if (updatingTextLockedBy === undefined) {
        updatingTextLockedBy = id;
        return true;
    }
    return updatingTextLockedBy === id;
}