import React from 'react';
import './App.css';
import './components/logos/logos.css';
import Header from "./components/header";
import CanvasDemo from "./components/CanvasDemo";
import {Experience} from "./components/Experience";
import {triggerUpdaterFromRegistry} from "./components/updatingText/registry";

setTimeout(triggerUpdaterFromRegistry, 2000);

class App extends React.Component {

    componentDidMount() {
        window.addEventListener("resize", () => this.forceUpdate())
    }

    render() {

        let body = (
            <div className="main-body">
                <div className={"links"}>
                    <a href={"mailto:hi.ben@dartoxia.com"}>hi.ben@dartoxia.com</a> | <a href={"https://github.com/Dartoxian"}>GitHub</a> | <a href={"https://www.linkedin.com/in/ben-gazzard/"}>LinkedIn</a>
                </div>
                <Experience/>
            </div>
        );

        const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        return (
            <div className="App">
                {vw > 900 && <div className={"left"}>
                    <CanvasDemo/>
                </div>}
                <div className="app-content">
                    <Header/>
                    {body}
                </div>
                {vw > 900 && <div className={"right"}>
                    <CanvasDemo/>
                </div>}
            </div>
        );
    }
}

export default App;
