import {useCallback, useEffect, useState} from "react";
import {
    getRegistryLock,
    registerControlLoopCallback,
    removeControlLoopCallback,
    triggerUpdaterFromRegistry
} from "./registry";
import {
    convertTextToGrid,
    findCursorPosition,
    moveCursorTowardsTarget,
    Position,
    updateWithDelete,
    updateWithInsert
} from "./utils";

const BASE_TIMEOUT = 50;

type UpdatingTextState = {
    currentText: string;
    grid: string[];
    cursor?: Position;
    mode?: 'movingToTarget' | "deletingCharacters" | "newCharacters" | 'done'
    lastReplacement?: string;
    target?: Position;
    changes?: number;
    replacementWords?: string[];
    startWord?: string;
    targetWord?: string;
}


export const useUpdatingText = (initialText: string, replacements: { [search in string]: string[] }, lineLength: number) => {
    const [id] = useState(Math.floor(Math.random() * (2 ^ 16)).toString());
    const [{
        currentText, grid, cursor, mode, lastReplacement,
        target, changes, startWord, targetWord, replacementWords
    }, setTextState] = useState<UpdatingTextState>({
        currentText: initialText,
        grid: convertTextToGrid(initialText, lineLength),
    })
    const [nextTimeout, setNextTimeout] = useState<number | undefined>(undefined);

    const startControlLoop = useCallback(() => {
        setNextTimeout(BASE_TIMEOUT);
    }, [setNextTimeout])

    const controlLoop = useCallback(() => {
        console.log("starting updater on", currentText)
        console.log("starting timeout", mode)
        let nextTimeout = BASE_TIMEOUT;

        const insertCharacter = () => {
            const {
                newText,
                newCursor,
                newGrid
            } = updateWithInsert(cursor, replacementWords[0][changes], currentText, grid, lineLength);

            if (changes + 1 === replacementWords[0].length) {
                if (replacementWords.length > 1) {
                    setTextState((state) => ({
                        ...state, mode: "deletingCharacters", changes: 0,
                        targetWord: replacementWords[0],
                        replacementWords: replacementWords.slice(1, replacementWords.length),
                        currentText: newText, cursor: newCursor, grid: newGrid
                    }));
                    nextTimeout = BASE_TIMEOUT * 3;
                } else if (replacementWords[0] === startWord) {
                    setTextState((state) => ({
                        ...state, mode: "done", changes: 0,
                        currentText: newText, cursor: newCursor, grid: newGrid
                    }));
                    setTimeout(() => {
                        const nextUpdatingTextId = triggerUpdaterFromRegistry();
                        if (id !== nextUpdatingTextId) {
                            setTextState((state) => ({...state, cursor: undefined}));
                        }
                    }, (5000 * Math.random()) + 2000);
                } else {
                    // Time to undo it all
                    setTextState((state) => ({
                        ...state, mode: "deletingCharacters", changes: 0,
                        targetWord: replacementWords[0],
                        replacementWords: [startWord],
                        currentText: newText, cursor: newCursor, grid: newGrid
                    }));
                    nextTimeout = BASE_TIMEOUT * 30;
                }
            } else {
                setTextState((state) => ({
                    ...state, changes: changes + 1,
                    currentText: newText, cursor: newCursor, grid: newGrid
                }))
            }
        }

        const deleteCharacter = () => {
            const {newText, newCursor, newGrid} = updateWithDelete(cursor, currentText, grid, lineLength);
            setTextState(s => ({...s, currentText: newText, cursor: newCursor, grid: newGrid}));
            if (changes + 1 === targetWord.length) {
                setTextState((state) => ({...state, mode: "newCharacters", changes: 0}));
            } else {
                setTextState((state) => ({...state, changes: state.changes + 1}))
            }
        }

        const chooseWord = () => {
            const targetWords = Object.keys(replacements).filter(word => lastReplacement !== word);
            const targetWord = targetWords[Math.floor(targetWords.length * Math.random()) % targetWords.length];
            if (!targetWord) {
                console.error(`Did not get a target word from ${targetWords} for ${initialText}`)
            }
            let characterPosition = currentText.indexOf(targetWord) + targetWord.length;
            if (characterPosition < 0) {
                console.error("Misconfiguration, cannot find word", targetWord);
                console.error("in", grid);
            }
            const target = findCursorPosition(characterPosition, grid);

            let nextCursor = {
                x: Math.floor(Math.random() * lineLength) % lineLength,
                y: Math.floor(Math.random() * grid.length) % grid.length,
            };
            if (!!cursor) {
                nextCursor = cursor;
            }

            setTextState((state) => ({
                ...state, mode: "movingToTarget",
                target, cursor: nextCursor,
                startWord: targetWord, targetWord, replacementWords: replacements[targetWord], changes: 0
            }))
        }

        const moveToTarget = () => {
            if (cursor.x === target.x && cursor.y === target.y) {
                setTextState((state) => ({...state, mode: "deletingCharacters", changes: 0}))
            } else {
                setTextState(s => ({...s, cursor: moveCursorTowardsTarget(cursor, target)}));
            }
        }

        const done = () => {
            setNextTimeout(undefined)
            setTextState((state) => ({
                ...state, mode: undefined, targetWord: undefined, startWord: undefined, replacementWords: undefined,
                lastRun: state.startWord,
            }));
        }

        if (!getRegistryLock(id)) {
            done();
            console.log("Unexpectedly exiting control loop")
            return;
        }

        switch (mode) {
            case undefined:
                chooseWord();
                break;
            case "movingToTarget":
                moveToTarget();
                break;
            case "deletingCharacters":
                deleteCharacter();
                break;
            case "newCharacters":
                insertCharacter()
                break;
            case "done":
                done();
                return;
        }
        setNextTimeout(nextTimeout)
    }, [id, replacements, initialText, lineLength, currentText, grid, cursor, mode, lastReplacement,
        target, changes, startWord, targetWord, replacementWords]);

    useEffect(() => {
        setTextState(s => ({
            ...s,
            grid: convertTextToGrid(initialText, lineLength), currentText: initialText
        }))
    }, [initialText, lineLength])

    useEffect(() => {
        if (!!nextTimeout) {
            const timeout = setTimeout(() => {
                controlLoop();
            }, nextTimeout);
            return () => clearTimeout(timeout)
        }
    }, [controlLoop, nextTimeout])

    useEffect(() => {
        registerControlLoopCallback(id, startControlLoop);
        return () => removeControlLoopCallback(id);
    }, [id, startControlLoop])

    return {
        grid, cursor
    }
}